<template>
    <v-time-picker
        :model-value="_modelValue"
        :format="format"
        :ampm-in-title="format === 'ampm'"
        @update:hour="onHourUpdate"
        @update:minute="onMinuteUpdate"
        @update:period="onPeriodUpdate"
    />
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { VTimePicker } from 'vuetify/labs/VTimePicker';

interface Props {
    modelValue: string;
    format?: 'ampm' | '24hr';
}

const props = withDefaults(defineProps<Props>(), { format: 'ampm' });
const _modelValue = useVModel(props, 'modelValue');

function onHourUpdate(value: number) {
    _modelValue.value = `${value.toString().padStart(2, '0')}:${_modelValue.value.slice(3, 5)}`;
}

function onMinuteUpdate(value: number) {
    _modelValue.value = `${_modelValue.value.slice(0, 2)}:${value.toString().padStart(2, '0')}`;
}

function onPeriodUpdate(value: 'am' | 'pm') {
    if (props.format !== 'ampm') return;

    const hour = parseInt(_modelValue.value.slice(0, 2));

    if (value === 'am' && hour >= 12) {
        _modelValue.value = `${Math.max(hour - 12, 0)
            .toString()
            .padStart(2, '0')}:${_modelValue.value.slice(3, 5)}`;
    } else if (value === 'pm' && hour < 12) {
        _modelValue.value = `${Math.min(hour + 12, 23)
            .toString()
            .padStart(2, '0')}:${_modelValue.value.slice(3, 5)}`;
    }
}
</script>

import type { GetTripModeDto } from '@/types/webapi';

export enum WalkSpeedType {
    Normal,
    Slow,
    Fast
}

export interface TripSearchFilters {
    modes: GetTripModeDto[];
    walkSpeed: WalkSpeedType;
    transferSlack: number;
    showTripsWithTransfers: boolean;
    lines: string[];
}

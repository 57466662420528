<template>
    <v-confirm-edit ref="confirmEditRef" v-model="_modelValue">
        <template #default="{ model: proxyModel, actions: _, isPristine }">
            <v-card class="dev-mode-configurator">
                <v-card-title class="px-4 pt-4 pb-2">
                    <span class="text-h3">Dev Mode Configurations</span>
                </v-card-title>
                <v-divider class="mx-4" />
                <v-card-text class="pa-4">
                    <v-sheet color="grey-lighten-3" rounded class="pa-2 my-4">
                        <v-row dense>
                            <v-col cols="12" md="auto" class="d-flex align-center">
                                <p class="text-h5 font-weight-regular">Debug Mode</p>
                            </v-col>
                            <v-spacer />
                            <v-col cols="12" md="auto">
                                <v-switch v-model="proxyModel.value.enableDebugMode" hide-details inset color="primary" density="compact" />
                            </v-col>
                        </v-row>
                    </v-sheet>

                    <p class="text-h5">API Endpoints</p>

                    <v-sheet color="grey-lighten-3" rounded class="pa-2 my-4">
                        <v-row dense>
                            <v-col cols="12" md="auto" class="d-flex align-center">
                                <p class="text-h5 font-weight-regular">Entur</p>
                            </v-col>
                            <v-spacer />
                            <v-col cols="12" md="auto">
                                <v-btn-toggle
                                    v-model="proxyModel.value.enturEndpointType"
                                    color="primary"
                                    group
                                    mandatory
                                    density="compact"
                                >
                                    <v-btn :value="0"> Default </v-btn>
                                    <v-btn :value="1"> Dev </v-btn>
                                    <v-btn :value="2"> Test </v-btn>
                                    <v-btn :value="3"> Prod </v-btn>
                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                    </v-sheet>

                    <v-sheet color="grey-lighten-3" rounded class="pa-2 my-4">
                        <v-row dense>
                            <v-col cols="12" md="auto" class="d-flex align-center">
                                <p class="text-h5 font-weight-regular">SanntidNG</p>
                            </v-col>
                            <v-spacer />
                            <v-col cols="12" md="auto">
                                <v-btn-toggle v-model="proxyModel.value.ngEndpointType" color="primary" group mandatory density="compact">
                                    <v-btn :value="0"> Default </v-btn>
                                    <v-btn :value="1"> Dev </v-btn>
                                    <v-btn :value="2"> Test </v-btn>
                                    <v-btn :value="3"> Prod </v-btn>
                                </v-btn-toggle>
                            </v-col>
                            <v-col cols="12">
                                <p class="text-subtitle-1">
                                    <strong>NOTE:</strong> Modifying this value may cause discrepancies between bookings stored in the
                                    database with the ones stored on the API.
                                </p>
                            </v-col>
                        </v-row>
                    </v-sheet>

                    <p class="text-h5">Features</p>

                    <v-sheet color="grey-lighten-3" rounded class="pa-2 my-4">
                        <v-row dense>
                            <v-col cols="12" md="auto" class="d-flex align-center">
                                <p class="text-h5 font-weight-regular">Booking</p>
                            </v-col>
                            <v-spacer />
                            <v-col cols="12" md="auto">
                                <v-switch v-model="proxyModel.value.enableBooking" hide-details inset color="primary" density="compact" />
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <v-sheet color="grey-lighten-3" rounded class="pa-2 my-4">
                        <v-row dense>
                            <v-col cols="12" md="auto" class="d-flex align-center">
                                <p class="text-h5 font-weight-regular">AI Search</p>
                            </v-col>
                            <v-spacer />
                            <v-col cols="12" md="auto">
                                <v-switch v-model="proxyModel.value.enableAiSearch" hide-details inset color="primary" density="compact" />
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-card-text>

                <v-divider class="mx-4" />

                <v-card-actions class="px-4">
                    <v-btn @click="reset">Defaults</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn @click="cancel">Cancel</v-btn>
                    <v-btn color="primary" :disabled="isPristine" @click="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-confirm-edit>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { ref } from 'vue';
import { VConfirmEdit } from 'vuetify/components';
import { DevModeConfiguration } from '../../models';

interface Props {
    modelValue?: DevModeConfiguration;
}

interface Emits {
    (e: 'update:modelValue', value: DevModeConfiguration): void;
    (e: 'save'): void;
    (e: 'cancel'): void;
    (e: 'reset'): void;
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: () => ({
        enturEndpointType: 0,
        ngEndpointType: 0,
        enableAiSearch: false,
        enableBooking: false,
        enableDebugMode: false
    })
});
const emit = defineEmits<Emits>();
const _modelValue = useVModel(props, 'modelValue');
const confirmEditRef = ref<InstanceType<typeof VConfirmEdit>>();

function save() {
    confirmEditRef.value?.save();
    emit('save');
}

function cancel() {
    confirmEditRef.value?.cancel();
    emit('cancel');
}

function reset() {
    emit('reset');
}
</script>

<style lang="scss">
.dev-mode-configurator .v-switch.v-switch--inset .v-selection-control--dirty .v-switch__track {
    transition: opacity 0.2s;
    opacity: 1;
}
</style>

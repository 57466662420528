/// <reference types="leaflet-contextmenu" />
import { type ContextMenuItem, LatLngBounds, type LatLngExpression, type PointTuple } from 'leaflet';
import { defineStore } from 'pinia';
import { useMainStore } from '@/features/common/stores';
import { computed, reactive, toRefs, watch } from 'vue';

const useMapStore = defineStore('mapStore', () => {
    const mainStore = useMainStore();

    const state = reactive({
        center: { lat: 58.97, lng: 5.7331 } as LatLngExpression,
        zoom: 15,
        bounds: new LatLngBounds([
            [0, 0],
            [0, 0]
        ]),
        userLocation: null as GeolocationCoordinates | null,
        paddingTopLeft: undefined as PointTuple | undefined,
        paddingBottomRight: undefined as PointTuple | undefined,
        grayscale: false,
        contextmenu: undefined as ContextMenuItem[] | undefined
    });

    const googleMutantOptions = computed(() => ({
        apiKey: mainStore.config.googleMapsConfig.apiKey,
        mapId: mainStore.config.googleMapsConfig.cloudStyleMapId
    }));

    watch(
        () => mainStore.config.googleMapsConfig.center,
        value => {
            const { latitude: lat, longitude: lng } = value;
            state.center = { lat, lng };
        },
        { deep: true }
    );

    return {
        ...toRefs(state),
        googleMutantOptions
    };
});

export default useMapStore;

import type { GetPlacesAutocompleteResultDto, GetPlaceDetailsResultDto, PlaceAutocompleteModelDto } from '@/types/webapi';
import { AxiosInstance, IAxiosId } from '@/plugins/axios';
import { inject, injectable } from 'inversify';
import type { IPlaceService, IPlaceServiceOptions } from '.';
import ServiceOptions from './ServiceOptions';
import { type LatLngLiteral } from 'leaflet';

@injectable()
export class PlaceService implements IPlaceService {
    @inject(IAxiosId)
    protected axios: AxiosInstance;

    async getPlacesAutocomplete(keyword: string, options?: IPlaceServiceOptions) {
        const response = await this.axios.get<GetPlacesAutocompleteResultDto>('/api/v1.0/places/autocomplete', {
            signal: options?.signal,
            params: {
                keyword,
                origin: options?.origin ? options?.origin.lat + ',' + options?.origin.lng : null,
                stopPlacesOnly: options?.stopPlacesOnly || false
            }
        });

        return response.data.data || [];
    }

    async getPlaceDetails(id: string, options?: ServiceOptions): Promise<PlaceAutocompleteModelDto | null> {
        const response = await this.axios.get<GetPlaceDetailsResultDto>(`/api/v1.0/places/details/${id}`, {
            signal: options?.signal
        });

        return response.data.data || null;
    }

    async getPlaceDetailsForGeolocation(
        latLng: LatLngLiteral,
        options?: ServiceOptions | undefined
    ): Promise<PlaceAutocompleteModelDto | null> {
        const response = await this.axios.get<GetPlaceDetailsResultDto>(`/api/v1.0/places/lookup?coordinates=${latLng.lat},${latLng.lng}`, {
            signal: options?.signal
        });

        return response.data.data || null;
    }
}

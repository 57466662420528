import { syncRef, useSessionStorage, watchDebounced } from '@vueuse/core';
import { ref, toValue, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useMainStore } from '../stores';
import { storeToRefs } from 'pinia';
import { DevModeConfiguration } from '../models';
import { isEmpty, merge } from 'lodash-es';

const MIN_THRESHOLD = 10;

export function useDevModeActivator() {
    const mainStore = useMainStore();
    const { isDevMode, devModeConfiguration, config, defaultConfig } = storeToRefs(mainStore);

    const devModeSessionStorage = useSessionStorage<{ enabled?: boolean } | undefined>(
        'travelplanner__devMode',
        {},
        { writeDefaults: false }
    );
    const devModeConfigurationSessionStorage = useSessionStorage<DevModeConfiguration | undefined>(
        'travelplanner__devModeConfig',
        {},
        { writeDefaults: false }
    );

    const router = useRouter();
    const { currentRoute } = router;
    const modeChangeCounter = ref(0);
    const dialogIsVisible = ref(false);
    const showDevModeNotification = ref(false);

    function init() {
        syncRef(devModeSessionStorage, isDevMode, {
            transform: {
                rtl: right => (right ? { enabled: true } : undefined),
                ltr: left => left?.enabled || false
            }
        });

        // set initial value from config
        if (config.value.devModeEnabled) {
            isDevMode.value = true;
        }

        if (isDevMode.value) {
            setInitialDevModeConfiguration();
        } else {
            // start activator trigger if devMode isn't already activated
            const unwatchRoute = watch(currentRoute, (value, oldValue) => {
                if (value.name === oldValue.name) return;

                modeChangeCounter.value += 1;
            });

            const unwatchDebounced = watchDebounced(modeChangeCounter, () => (modeChangeCounter.value = 0), {
                debounce: 10000,
                maxWait: 15000
            });

            const unwatchCounter = watch(modeChangeCounter, value => {
                if (value < MIN_THRESHOLD) return;

                // once enabled, we no longer need this
                unwatchCounter();
                unwatchRoute();
                unwatchDebounced();

                setInitialDevModeConfiguration();
                isDevMode.value = true;
                showDevModeNotification.value = true;
            });
        }

        syncRef(devModeConfiguration, devModeConfigurationSessionStorage, {
            transform: {
                ltr: left => (left ? { ...left } : undefined)
            },
            direction: 'ltr'
        });

        syncRef(devModeConfiguration, config, {
            transform: {
                ltr: left => {
                    return merge({}, toValue(config), {
                        bookingConfig: {
                            enabled: left && isDevMode.value ? left.enableBooking : defaultConfig.value?.bookingConfig?.enabled
                        },
                        isDebugModeEnabled: left && isDevMode.value ? left.enableDebugMode : defaultConfig.value?.isDebugModeEnabled
                    });
                }
            },
            direction: 'ltr'
        });
    }

    function setInitialDevModeConfiguration() {
        // use sessionStorage value if available, otherwise use default config
        devModeConfiguration.value = !isEmpty(devModeConfigurationSessionStorage.value)
            ? devModeConfigurationSessionStorage.value
            : {
                  enableBooking: !!defaultConfig.value?.bookingConfig?.enabled,
                  enableDebugMode: !!defaultConfig.value?.isDebugModeEnabled,
                  enableAiSearch: false,
                  enturEndpointType: 0,
                  ngEndpointType: 0
              };
    }

    function resetConfig() {
        // clear sessionStorage
        devModeConfigurationSessionStorage.value = undefined;
        // reset config
        setInitialDevModeConfiguration();
        dialogIsVisible.value = false;
    }

    return { dialogIsVisible, showDevModeNotification, init, resetConfig };
}

<template>
    <v-input class="k-input" hide-details :class="cssClasses">
        <template #default="{ id, isDisabled, isDirty, isValid }">
            <v-field
                v-bind="fieldProps"
                :id="id.value"
                :disabled="isDisabled.value"
                :dirty="isDirty.value"
                :error="isValid.value === false"
                active
                variant="plain"
            >
                <template #default="{ props: { class: fieldClass, ...slotProps } }">
                    <div :class="fieldClass" v-bind="{ ...slotProps }">
                        <slot v-bind="{ id, isDisabled, isDirty, isValid }" />
                    </div>
                </template>

                <template v-if="attrs.label" #label="{ label }">
                    <span class="text-body-1 text-secondary">{{ label }}</span>
                </template>
            </v-field>
        </template>
    </v-input>
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue';
// @ts-ignore
import { filterFieldProps } from 'vuetify/lib/components/VField/VField.mjs';

const attrs = useAttrs();
const fieldProps = filterFieldProps(attrs);
const cssClasses = computed(() => ({ 'k-input--has-label': !!attrs.label }));
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.k-input {
    .v-field {
        --v-field-input-padding-top: 0;
    }

    &--has-label .v-field {
        --v-field-input-padding-top: #{$spacer * 7};
    }

    .v-field__input {
        --v-high-emphasis-opacity: 1;
    }

    &.v-input {
        font-weight: 400;
    }

    .v-label {
        color: var(--v-color-grey-darken-4);
        font-weight: 500;
        opacity: 1;
    }
}
</style>

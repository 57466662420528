<template>
    <v-alert type="info" variant="tonal" density="compact" icon="false" rounded="0" class="border-0 border-b-sm">
        <div v-html="t('common.resources.linkToOldTravelPlanner', 'N/A')"></div>
    </v-alert>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss">
.v-alert {
    background-color: white;

    a {
        color: #498455;
    }

    hr {
        margin: 8px 0;
        color: #d1d1d1;
    }

    .v-alert__underlay {
        background-color: #94d69a;
    }

    .v-alert__prepend {
        display: none;
    }

    &.text-info {
        color: black !important;
    }
}
</style>

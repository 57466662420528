import { isUndefined } from 'lodash-es';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { CustomError } from '../models';

function _getGeolocation(abortController?: AbortController): Promise<GeolocationPosition | null> {
    return new Promise((resolve, reject) => {
        if (abortController) {
            abortController.signal.addEventListener('abort', () => resolve(null));
        }

        navigator.geolocation.getCurrentPosition(
            position => {
                if (abortController?.signal.aborted) return;

                resolve(position);
            },
            reject,
            { enableHighAccuracy: true, timeout: 30000 }
        );
    });
}

export function useGeolocationHelper() {
    const { t } = useI18n();
    const isSupported = computed(() => !isUndefined(navigator.geolocation));

    async function getCurrentPosition(abortController?: AbortController) {
        // abort any existing requests
        abortController?.abort();

        try {
            // reset abortController
            abortController = new AbortController();
            // request for geolocation
            const position = await _getGeolocation(abortController);
            // set if geolocation is valid
            if (!position)
                throw new CustomError(
                    'GeolocationPositionError',
                    t('places.resources.errorMessages.geolocationGeneralError', 'Geolocation failed. Please try again.')
                );

            return position;
        } catch (e) {
            if (e instanceof GeolocationPositionError) {
                switch (e.code) {
                    case 1:
                        throw new CustomError(
                            'GeolocationPositionError',
                            t(
                                'places.resources.errorMessages.geolocationPermissionError',
                                'Please allow the browser to access your location.'
                            )
                        );

                    default:
                        throw new CustomError(
                            'GeolocationPositionError',
                            t('places.resources.errorMessages.geolocationGeneralError', 'Geolocation failed. Please try again.')
                        );
                }
            }

            throw e;
        }
    }

    return {
        isSupported,
        getCurrentPosition
    };
}

import { BookingDto, CreateBookingDto, StopSignalOrderDto } from '@/types/webapi';
import ServiceOptions from './ServiceOptions';

export const IBookingServiceId = Symbol.for('IBookingService');

export interface GetBookingsFilter {
    serviceJourneyRef?: string;
    activeDate?: Date;
    stopSequence?: number;
}

export interface GetStopSignalOrderParams {
    serviceJourneyRef: string;
    activeDate: Date;
    stopSequence: number;
}

export interface IBookingService {
    /**
     * Get all bookings for current user.
     */
    getAllBookings(options?: ServiceOptions): Promise<BookingDto[]>;

    /**
     * Get all bookings for current user.
     * @param filters Filters to use when fetching bookings
     */
    getBookings(filters?: GetBookingsFilter, options?: ServiceOptions): Promise<BookingDto[]>;

    /**
     * Get single booking by ID.
     * @param id Booking ID
     */
    getBooking(id: string): Promise<BookingDto>;

    /**
     * Create new booking
     * @param params Values to pass to API when creating new booking
     */
    createBooking(params: CreateBookingDto): Promise<BookingDto>;

    /**
     * Cancel existing booking
     * @param id Booking ID
     */
    cancelBooking(id: string): Promise<BookingDto>;

    /**
     * Get stop signal order for serviceJourney, null if it does not exist.
     * @param params Parameters to pass to API service
     */
    getStopSignalOrder(params: GetStopSignalOrderParams, options?: ServiceOptions): Promise<StopSignalOrderDto | undefined>;
}

import { LatLngExpression } from 'leaflet';
import { InjectionKey, Ref } from 'vue';

interface AppProvide {
    currentTime: Ref<Date>;
    setDrawerFullHeight: (fullHeight: boolean) => void;
}

interface SearchPageProvide {
    setHeaderVisibility: (value: boolean) => void;
    setHeaderAppendVisibility: (value: boolean) => void;
    setPage: (value: number) => void;
}

interface MapUnderlayProvide {
    flyTo(latLng: LatLngExpression, zoom?: number): void;
}

export const AppProvideKey: InjectionKey<AppProvide> = Symbol.for('travelplanner:app');
export const SearchPageProvideKey: InjectionKey<SearchPageProvide> = Symbol.for('travelplanner:searchWrapper');
export const MapUnderlayProvideKey: InjectionKey<MapUnderlayProvide> = Symbol.for('travelplanner:mapUnderlay');

import { type StorageLike, useStorage } from '@vueuse/core';
import { computed, toValue } from 'vue';

// TODO: set up mechanism to change this
const maxEntryLength = 3;

export function usePlaceAutocompleteHistory<T extends { id: string } & unknown>(name: string, storage?: StorageLike) {
    if (!name) throw new Error('You must specify a name for the storage location.');

    const historyStorage = useStorage<T[]>(`travelplanner__${name}`, [], storage);
    // readonly modifier for storage list so consumers cannot modify it
    const items = computed(() => historyStorage.value || []);

    /**
     * Adds item to history. Removes oldest item if resulting list is longer than maxEntryLength. Promotes existing item to top of list.
     * @param item Item to add to history
     */
    function addItem(item: T) {
        const _items = toValue(items);
        const existingItemIndex = _items.findIndex(x => x.id === item.id);

        if (existingItemIndex > -1) {
            // move existing item item to top of list
            const existingItem = _items.splice(existingItemIndex, 1);
            _items.unshift(existingItem[0]);
        } else {
            _items.unshift(item);

            // if item exceeds maxEntryLength, remove last item
            if (_items.length > maxEntryLength) _items.pop();
        }

        historyStorage.value = _items;
    }

    /**
     * Clears all data from storage
     */
    function clear() {
        historyStorage.value = [];
    }

    return {
        items,
        addItem,
        clear
    };
}

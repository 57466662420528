import '@mdi/font/css/materialdesignicons.css';
import '@geta/kolumbus-assets';
import 'vuetify/styles';
import '@geta/kolumbus-frontend/styles';
import '@/styles/main.scss';
import 'reflect-metadata';
import { createApp } from 'vue';
import { createVuetify } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import App from './App.vue';
import pinia from './store';
import router from './router';
import i18n from './i18n';
import v1 from '@geta/kolumbus-frontend/blueprints/v1';
import { createContainer } from '@/plugins/inversify';
import { useI18n } from 'vue-i18n';
import { useMainStore } from './features/common/stores';
import { VueReCaptcha } from 'vue-recaptcha-v3-esm';

const app = createApp(App)
    .use(createContainer())
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(
        createVuetify({
            blueprint: v1,
            locale: {
                adapter: createVueI18nAdapter({ i18n, useI18n })
            }
        })
    );

const mainStore = useMainStore();
mainStore.loadConfig().then(() => {
    if (mainStore.config?.googleRecaptcha?.siteKey) {
        app.use(VueReCaptcha, {
            siteKey: mainStore.config.googleRecaptcha.siteKey,
            loaderOptions: { autoHideBadge: true }
        });
    }

    app.mount('#app');
});

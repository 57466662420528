<template>
    <v-card ref="root" flat color="transparent" class="datetime-picker">
        <v-defaults-provider :defaults="defaultProps">
            <v-card-text class="pa-0">
                <v-row dense>
                    <!-- timepicker -->
                    <v-col cols="6" md="5">
                        <v-menu v-model="timePickerIsVisible" location="bottom left" :min-width="timepickerWidth" :width="timepickerWidth">
                            <template #activator="{ props: activatorProps, isActive }">
                                <v-text-field
                                    ref="timePickerInput"
                                    :model-value="timeValue"
                                    :base-color="isActive ? color : baseColor"
                                    :active="isActive"
                                    :focused="isActive"
                                    prepend-inner-icon="mdi-chevron-left"
                                    class="datetime-picker__timepicker font-weight-regular"
                                    :class="getInputClasses(isActive)"
                                    @click:append="showDatePicker"
                                    @click:prepend-inner="decreaseTime"
                                >
                                    <template #append-inner>
                                        <v-icon icon="mdi-chevron-right" size="24" color="secondary" @click="increaseTime" />
                                        <v-icon v-bind="activatorProps" icon="mdi-clock-outline" size="24" color="secondary" />
                                    </template>
                                </v-text-field>
                            </template>

                            <v-card class="datetime-picker__dialog" :class="textColorClasses">
                                <time-picker
                                    v-model="tempTimeValue"
                                    :color="color"
                                    format="24hr"
                                    :min="_minTime"
                                    :max="_maxTime"
                                    :title="t('common.resources.timePicker.title')"
                                    class="w-100"
                                />

                                <v-card-actions class="px-4 pb-4">
                                    <v-btn
                                        variant="plain"
                                        color="primary"
                                        :text="t('common.resources.timePicker.cancel')"
                                        @click="hideTimePicker"
                                    />
                                    <v-spacer />
                                    <v-btn
                                        variant="flat"
                                        color="primary"
                                        :text="t('common.resources.timePicker.save')"
                                        @click="setTimeValue(tempTimeValue)"
                                    />
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-col>
                    <!-- datepicker -->
                    <v-col cols="6" md="7">
                        <v-menu v-model="datePickerIsVisible" location="bottom right" :min-width="calendarWidth" :width="calendarWidth">
                            <template #activator="{ props: activatorProps, isActive }">
                                <v-text-field
                                    ref="datePickerInput"
                                    v-bind="activatorProps"
                                    :model-value="format(_localeModelValue, 'dd.MM.yyyy')"
                                    :base-color="isActive ? color : baseColor"
                                    class="font-weight-regular"
                                    :class="getInputClasses(isActive)"
                                    @click:append-inner="showDatePicker"
                                >
                                    <template #append-inner>
                                        <v-icon icon="mdi-calendar-month-outline" size="24" color="secondary" />
                                    </template>
                                </v-text-field>
                            </template>

                            <v-card :width="calendarWidth" class="datetime-picker__dialog" :class="textColorClasses">
                                <v-date-picker
                                    v-model="dateValue"
                                    :min="_minDate"
                                    :max="_maxDate"
                                    elevation="0"
                                    :color="color"
                                    hide-header
                                    show-adjacent-months
                                    @update:model-value="hideDatePicker"
                                >
                                </v-date-picker>
                            </v-card>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-defaults-provider>
    </v-card>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, toValue, watch } from 'vue';
import { useElementSize } from '@vueuse/core';
import { addMinutes, endOfDay, format, isSameDay, isValid, parse, set, startOfDay } from 'date-fns';
import { toLocalDate, toUtcDate } from '@geta/kolumbus-frontend/composables/DateHelpers';
import { useDisplayHelpers } from '@/features/common/composables';
import type { VCard, VTextField } from 'vuetify/components';
// @ts-ignore
import { useTextColor } from 'vuetify/lib/composables/color.mjs';
import { TimePicker } from '../TimePicker';
import { useI18n } from 'vue-i18n';

interface Props {
    /**
     * The selected date and time value
     * @default new Date()
     */
    modelValue?: Date;

    /**
     * The minimum selectable date
     */
    minDate?: Date | number;

    /**
     * The maximum selectable date
     */
    maxDate?: Date | number;

    /**
     * Whether the picker is disabled
     */
    disabled?: boolean;

    /**
     * Time increment/decrement step in minutes
     * @default 30
     */
    timeStepInMinutes?: number;

    /**
     * Controls the compactness of the component
     * @default 'default'
     */
    density?: 'default' | 'comfortable' | 'compact';

    /**
     * Visual style variant of the input fields
     * @default 'filled'
     */
    variant?: 'filled' | 'underlined' | 'outlined' | 'plain' | 'solo' | 'solo-inverted' | 'solo-filled';

    /**
     * Primary color for input fields
     */
    color?: string;

    /**
     * Base color for inactive states
     */
    baseColor?: string;

    /**
     * Background color of the input fields
     */
    bgColor?: string;

    /**
     * CSS class to apply to the base element
     */
    baseClass?: string;

    /**
     * CSS class to apply when the component is active
     */
    activeClass?: string;

    /**
     * Controls the border radius of the component
     */
    rounded?: boolean | string;
}

const { t } = useI18n();
const props = withDefaults(defineProps<Props>(), { timeStepInMinutes: 30 });
const _modelValue = defineModel<Date>('modelValue', { default: () => new Date() });
const _localeModelValue = computed({
    get() {
        // use locale time locally
        return toLocalDate(_modelValue.value);
    },
    set(value) {
        // return UTC value
        _modelValue.value = toUtcDate(value);
    }
});
const _minDate = computed(() => (props.minDate && isValid(props.minDate) ? startOfDay(props.minDate) : undefined));
const _maxDate = computed(() => (props.maxDate && isValid(props.maxDate) ? endOfDay(props.maxDate) : undefined));
const _minTime = computed(() =>
    props.minDate && isValid(props.minDate) && isSameDay(props.minDate, _modelValue.value) ? format(props.minDate, 'HH:mm') : undefined
);
const _maxTime = computed(() =>
    props.maxDate && isValid(props.minDate) && isSameDay(props.maxDate, _modelValue.value) ? format(props.maxDate, 'HH:mm') : undefined
);
const { isMobile } = useDisplayHelpers();

const datePickerIsVisible = ref(false);
const timePickerIsVisible = ref(false);
const { textColorClasses } = useTextColor(computed(() => props.color));

const defaultProps = computed(() => ({
    VMenu: {
        closeOnContentClick: false,
        target: 'parent',
        offset: [2, -4],
        transition: 'slide-y-transition'
    },
    VTextField: {
        hideDetails: true,
        readonly: true,
        disabled: props.disabled,
        density: props.density,
        variant: props.variant,
        color: props.color,
        bgColor: props.bgColor,
        rounded: props.rounded
    },
    VCard: {
        rounded: true
    }
}));

function getInputClasses(isActive?: boolean) {
    return [props.baseClass, isActive ? props.activeClass : ''].filter(Boolean);
}

const root = ref<typeof VCard>();
const rootElement = computed(() => root.value?.$el || null);
const { width: rootElementWidth } = useElementSize(rootElement);
const datePickerInput = ref<typeof VTextField>();
const timePickerInput = ref<typeof VTextField>();
const { width: datePickerInputWidth } = useElementSize(datePickerInput.value?.$el, undefined, { box: 'border-box' });
const { width: timePickerInputWidth } = useElementSize(timePickerInput.value?.$el, undefined, { box: 'border-box' });

const timepickerWidth = computed(() => (isMobile.value ? rootElementWidth.value : Math.max(360, timePickerInputWidth.value)));
const calendarWidth = computed(() => (isMobile.value ? rootElementWidth.value : Math.max(360, datePickerInputWidth.value)));

const dateValue = computed({
    get() {
        return startOfDay(_localeModelValue.value);
    },
    set(value) {
        _localeModelValue.value = set(_localeModelValue.value, {
            year: value.getFullYear(),
            month: value.getMonth(),
            date: value.getDate()
        });
    }
});

const timeValue = computed({
    get() {
        return format(_localeModelValue.value, 'HH:mm');
    },
    set(value) {
        // parse string to Date
        const parsedDate = parse(value, 'HH:mm', _localeModelValue.value);
        if (isValid(parsedDate)) {
            _localeModelValue.value = set(_localeModelValue.value, {
                hours: parsedDate.getHours(),
                minutes: parsedDate.getMinutes(),
                seconds: 0,
                milliseconds: 0
            });
        }
    }
});
const tempTimeValue = ref(toValue(timeValue.value));

function setTimeValue(value: string) {
    if (value === timeValue.value) return;

    timeValue.value = value;
    tempTimeValue.value = value;
    hideTimePicker();
}

function showDatePicker() {
    datePickerIsVisible.value = true;
}

function hideDatePicker() {
    datePickerIsVisible.value = false;
}

function hideTimePicker() {
    timePickerIsVisible.value = false;
}

function decreaseTime() {
    _localeModelValue.value = addMinutes(_localeModelValue.value, -(props.timeStepInMinutes || 30));
    nextTick(() => (tempTimeValue.value = toValue(timeValue.value)));
}

function increaseTime() {
    _localeModelValue.value = addMinutes(_localeModelValue.value, props.timeStepInMinutes || 30);
    nextTick(() => (tempTimeValue.value = toValue(timeValue.value)));
}

watch(
    () => props.disabled,
    value => {
        if (value) {
            hideDatePicker();
            hideTimePicker();
        }
    }
);
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.datetime-picker {
    overflow: visible;

    .v-field--variant-outlined {
        .v-field__outline {
            --v-field-border-opacity: 1 !important;
        }

        &.v-field--focused .v-field__outline {
            --v-field-border-width: 1px;
        }
    }

    .v-input--active-menu .v-field--variant-outlined {
        @include elevation(4);

        .v-field__outline {
            --v-field-border-width: 1px;
        }
    }

    .v-field__input {
        font-size: calc(16px / $font-size-root) * 1rem;
        height: 40px;

        &[type='time'] {
            text-align: center;
        }
    }

    .v-input__append .v-icon {
        opacity: 1;
    }

    &__dialog {
        border: 1px solid currentColor;

        .v-date-picker {
            width: 100% !important;
        }
    }

    &__timepicker .v-field__input {
        text-align: center;
    }

    .v-date-picker-month {
        padding: 0 10px 10px;
    }

    .v-date-picker-month__day {
        height: 44px;
        width: 44px;
    }
}
</style>

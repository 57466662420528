import { computed } from 'vue';
import { useDisplay } from 'vuetify';

export function useDisplayHelpers() {
    const { mdAndUp } = useDisplay();

    const isMobile = computed(() => !mdAndUp.value);

    return {
        isMobile
    };
}

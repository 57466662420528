import { useMainStore } from '@/features/common/stores';
import { AxiosInstance, IAxiosId } from '@/plugins/axios';
import { inject, injectable } from 'inversify';

@injectable()
export class ApiService {
    @inject(IAxiosId)
    protected axios: AxiosInstance;

    _mainStore: ReturnType<typeof useMainStore>;
    get mainStore(): ReturnType<typeof useMainStore> {
        if (!this._mainStore) {
            this._mainStore = useMainStore();
        }

        return this._mainStore;
    }
}

import {
    type LatLngBoundsExpression,
    type LatLngExpression,
    type PointExpression,
    type PointTuple,
    LatLngBounds,
    Map,
    point
} from 'leaflet';
import { type Ref } from 'vue';
import { type LMap } from '@vue-leaflet/vue-leaflet';
import { isArray, isEmpty, isUndefined } from 'lodash-es';

export interface PaddingOptions {
    padding?: PointTuple;
    paddingTopLeft?: PointTuple;
    paddingBottomRight?: PointTuple;
}

export function useMapHelpers(map: Ref<typeof LMap | undefined>) {
    function calcPaddingOffset(options?: PaddingOptions): PointExpression {
        if (isEmpty(options)) return [0, 0];

        const _paddingTL: PointTuple = isArray(options.paddingTopLeft)
                ? options.paddingTopLeft
                : isArray(options.padding)
                  ? options.padding
                  : [0, 0],
            _paddingBR: PointTuple = isArray(options.paddingBottomRight)
                ? options.paddingBottomRight
                : isArray(options.padding)
                  ? options.padding
                  : [0, 0];

        const paddingTL = point(_paddingTL),
            paddingBR = point(_paddingBR);

        return paddingBR.subtract(paddingTL).divideBy(2);
    }

    function addOffsets(latLng: LatLngExpression, zoom: number, options?: PaddingOptions): LatLngExpression {
        if (!map.value?.ready || isEmpty(options)) return latLng;

        const leafletObject = map.value.leafletObject as Map;
        const paddingOffset = calcPaddingOffset(options);

        return leafletObject.unproject(leafletObject.project(latLng, zoom).add(paddingOffset), zoom);
    }

    function removeOffsets(latLng: LatLngExpression, zoom: number, options?: PaddingOptions): LatLngExpression {
        if (!map.value?.ready || isEmpty(options)) return latLng;

        const leafletObject = map.value.leafletObject as Map;
        const paddingOffset = calcPaddingOffset(options);

        return leafletObject.unproject(leafletObject.project(latLng, zoom).subtract(paddingOffset), zoom);
    }

    return {
        addOffsets,
        removeOffsets
    };
}

export function isLeafletMap(value: unknown): value is Map {
    return !isUndefined(value) && value instanceof Map;
}

export function isLatLngBounds(value: LatLngBoundsExpression): value is LatLngBounds {
    return !isUndefined(value) && value instanceof LatLngBounds;
}

import { useMainStore } from '@/features/common/stores';
import { InternalAxiosRequestConfig } from 'axios';

export function devModeRequestInterceptor(config: InternalAxiosRequestConfig) {
    const mainStore = useMainStore();
    const { devModeConfiguration, isDevMode } = mainStore;

    if (isDevMode && devModeConfiguration) {
        config.headers['X-Booking-Enabled'] = devModeConfiguration.enableBooking || undefined;
        config.headers['X-Debug-Mode-Enabled'] = devModeConfiguration.enableDebugMode || undefined;
        config.headers['X-Entur-Endpoint-Type'] = devModeConfiguration.enturEndpointType || undefined;
        config.headers['X-Ng-Endpoint-Type'] = devModeConfiguration.ngEndpointType || undefined;
    }

    return config;
}

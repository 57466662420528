import { Container } from 'inversify';
import { ax, AxiosInstance, IAxiosId } from './axios';
import { InjectionKey, Plugin, inject } from 'vue';
import {
    IBookingService,
    IBookingServiceId,
    IPlaceService,
    IPlaceServiceId,
    ISearchService,
    ISearchServiceId,
    IConfigService,
    IConfigServiceId,
    ILinesService,
    ILinesServiceId
} from '@/services';
import { SearchService } from '@/services/SearchService';
import { PlaceService } from '@/services/PlaceService';
import { AppConfigService } from '@/services/AppConfigService';
import { LinesService } from '@/services/LinesService';
import { BookingService } from '@/services/BookingService';

const __INVERSIFY_SYMBOL__ = Symbol.for('app-inversify-container');
const injectionKey: InjectionKey<Container> = __INVERSIFY_SYMBOL__;

export function createContainer(): Plugin {
    const container = new Container();

    container.bind<AxiosInstance>(IAxiosId).toConstantValue(ax);

    container.bind<IPlaceService>(IPlaceServiceId).to(PlaceService).inSingletonScope();
    container.bind<ISearchService>(ISearchServiceId).to(SearchService).inSingletonScope();
    container.bind<IConfigService>(IConfigServiceId).to(AppConfigService);
    container.bind<ILinesService>(ILinesServiceId).to(LinesService).inSingletonScope();
    container.bind<IBookingService>(IBookingServiceId).to(BookingService).inSingletonScope();

    return {
        install(app) {
            app.provide(injectionKey, container);
        }
    };
}

export function useContainer() {
    const _container = inject(injectionKey);
    if (!_container) throw new Error('You must call createContainer() first');

    return _container;
}
